<template>
   <section class="blockchain">
      <b-container class="h-100">
         <h2 class="title-section">O QUE FAZEMOS (BLOCKCHAIN)?</h2>

         <div class="d-flex">
            <a href="./nft/marketplace.html" class="item-blockchain">
               <i><img src="@/assets/images/icon-marketplace.png" alt=""></i>
               <h6>Marketplace de NFTs</h6>
            </a>

            <a href="./nft/smart-contract.html" class="item-blockchain bg-red">
               <i><img src="@/assets/images/icon-cantract.png" alt=""></i>
               <h6>Smart Contract</h6>
            </a>

            <a href="./nft/token.html" class="item-blockchain">
               <i><img src="@/assets/images/icon-token.png" alt=""></i>
               <h6>Token</h6>
            </a>

            <a href="./nft/token-nft.html" class="item-blockchain bg-red">
               <i><img src="@/assets/images/icon-token-nft.png" alt=""></i>
               <h6>Token NFT</h6>
            </a>
         </div>
      </b-container>
   </section> 
</template>

<script>
  export default {
    name: 'Blockchain',
  }
</script>

<style lang="scss">
  @import "Blockchain.scss";
</style>
