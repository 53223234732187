<template>
   <div class="page-home">
      <div class="banner">
         <div class="conteudo">
            <u-animate-container>
               <u-animate
                  name="animate__fadeInDown"
                  delay="0.8s"
                  duration="1s"
                  :iteration="1"
                  :offset="0"
                  animateClass="animated"
                  :begin="false"
                  >
                  <i><img src="@/assets/images/banner-bem-vindo.png" alt=""></i>
               </u-animate>
            </u-animate-container>
         </div>
      </div>

      <section class="section-sobre">
         <b-container class="h-100">
            <h1 class="title-big">INSIGHT <span>SOLUTION</span></h1>

            <div class="img-icone">
               <img src="@/assets/images/icone-logo-branco.svg" alt="">
            </div>
            <div class="box-text">
               <p>Somos um empresa de desenvolvimento web, trabalhamos para gerar soluções inteligentes para seu negócio, estamos presentes no mercado desenvolvendo produtos web de forma ágil e completa. Conte com nossas habilidades e diferenciais para viabilizar seu negócio, novo ou melhorando o que você já tem, com nossa experiência de mais 5 anos no mercado, desenvolvemos produtos digitais, sites, aplicativos, e ferramentas web com excelência</p>

               <p class="text-destaque"><strong>Participe de nosso planejamento e entenda nossos processos, para otimizar</strong> as oportunidades do seu negócio e te ajudar com o que você precisa.</p>
            </div>
         </b-container>
      </section>

      <section class="section-nossos-trabalhos">
         <div class="carosel-nosso-trabalho">
            <VueSlickCarousel v-bind="settings">
               <div class="box">
                  <i><img src="@/assets/images/application-brackets-outline.svg" alt="CONFIDENCIALIDADE"></i>
                  <h4>SOFTWARE PERSONALIZADO</h4>
                  <p>Criamos um software personalizado para suas necessidades, tornando seu negócio ainda mais ágil e seguro.</p>
               </div>
               <div class="box">
                  <i><img src="@/assets/images/family-tree.svg" alt="MARKETING MULTINÍVEL - MMN"></i>
                  <h4>MARKETING MULTINÍVEL - MMN</h4>
                  <p>Um formato de negócio para gerar engajamento e crescimento para um futuro plano de parceiros e afiliados.</p>
               </div>
               <div class="box">
                  <i><img src="@/assets/images/cellphone-cog.svg" alt="APLICATIVOS"></i>
                  <h4>APLICATIVOS</h4>
                  <p>Desenvolvemos em plataformas IOS e Android trazendo acessibilidade e mobilidade ao seu sistema.</p>
               </div>
               <div class="box">
                  <i><img src="@/assets/images/handshake-outline.svg" alt="CONSULTORIA"></i>
                  <h4>CONSULTORIA</h4>
                  <p>Com uma boa comunicação, trocamos conhecimentos, competências e aconselhamentos para melhorar a inteligência do seu negócio.</p>
               </div>
            </VueSlickCarousel>
         </div>
      </section>

      <section class="section-destaques">
         <b-container class="h-100">
            <div class="box-destaque">
               <ul>
                  <li>
                     <i><img src="@/assets/images/security.svg" alt="CONFIDENCIALIDADE"></i>
                     <h4>CONFIDENCIALIDADE</h4>
                     <p>Nós cuidamos da segurança dos seus dados</p>
                  </li>

                  <li class="bg-1">
                     <i><img src="@/assets/images/smartwatch.svg" alt="FLEXIBILIDADE"></i>
                     <h4>FLEXIBILIDADE</h4>
                     <p>Nós cuidamos da segurança dos seus dados</p>
                  </li>

                  <li class="bg-2">
                     <i><img src="@/assets/images/bow.svg" alt="FOCO EM RESULTADOS"></i>
                     <h4>FOCO EM RESULTADOS</h4>
                     <p>Experiências em projetos, usuários conectados</p>
                  </li>
               </ul>
            </div>
         </b-container>
      </section>

      <div class="borda-destaque"></div>

      <section class="section-blockchain">
         <Blockchain></Blockchain>
      </section>

      <div class="borda-blockchain"></div>

      <section class="section-projetos">
         <b-container class="h-100">
            <h2 class="title-section">CONHEÇA ALGUNS DOS <span>NOSSOS NÚMEROS</span></h2>

            <div class="box-numeros">
               <ul>
                  <li>
                     <i><img src="@/assets/images/family-tree-azul.svg" alt=""></i>
                     <div class="da">PROJETOS</div>
                     <div class="numeros"><strong>+</strong> 200</div>
                  </li>
                  <li>
                     <i><img src="@/assets/images/family-tree-azul.svg" alt=""></i>
                     <div class="da">TRANSAÇÕES</div>
                     <div class="numeros"><strong>+</strong> 10 Milhões</div>
                  </li>
                  <li>
                     <i><img src="@/assets/images/family-tree-azul.svg" alt=""></i>
                     <div class="da">TRANSICIONADOS</div>
                     <div class="numeros"><strong>+</strong> 2 Bilhões</div>
                  </li>
                  <li>
                     <i><img src="@/assets/images/family-tree-azul.svg" alt=""></i>
                     <div class="da">CLIENTES</div>
                     <div class="numeros"><strong>+</strong> 1 Milhão</div>
                  </li>
               </ul>
            </div>

            <!-- <div class="box-projeto">
               <div class="item-projeto wow fadeInUp" data-wow-delay="0ms">
                  <div class="bg-sistema sistema-bigcash">
                     <a href="case-bigcash.html">
                        <div class="nome-projeto">Aplicativo e sistema web</div>
                        <div class="cliente">Cliente: <span>Bigcash</span></div>
                     </a>
                  </div>
               </div>
               <div class="item-projeto wow fadeInUp" data-wow-delay="100ms">
                  <div class="bg-sistema sistema-boxys">
                     <a href="case-boxys.html">
                        <div class="nome-projeto">Aplicativo e sistema web</div>
                        <div class="cliente">Cliente: <span>Boxys Self Storage</span></div>
                     </a>
                  </div>
               </div>
               <div class="item-projeto wow fadeInUp" data-wow-delay="200ms">
                  <div class="bg-sistema sistema-odyn">
                     <a href="case-odyn.html">
                        <div class="nome-projeto">Site Responsivo</div>
                        <div class="cliente">Cliente: <span>Odyn Bar</span></div>
                     </a>
                  </div>
               </div>
               <div class="item-projeto wow fadeInUp" data-wow-delay="300ms">
                  <div class="bg-sistema sistema-mavell">
                     <a href="case-mavell.html">
                        <div class="nome-projeto">Site responsivo e plataforma de MMN</div>
                        <div class="cliente">Cliente: <span>Mavell</span></div>
                     </a>
                  </div>
               </div>
            </div> -->
         </b-container>
      </section>

      <section class="section-agenciaTag">
         <b-container class="h-100">
            <i><img src="@/assets/images/logo-tag.svg" alt=""></i>
            <div class="box-texto">
               <h2 class="title-section">CONHEÇA NOSSA PARCERIA COM A AGÊNCIA DE MARKETING DIGITAL TAG</h2>
               <p>Conte com uma agência full digital que conecta criatividade e inovação para superar as expectativas e criar novas.</p>
            </div>
            <ul>
               <li><h3>Google ADS</h3></li>
               <li><h3>Anúncios patrocinados</h3></li>
               <li><h3>Criação de campanhas</h3></li>
               <li><h3>Inbound marketing</h3></li>
               <li><h3>Otimização de conversão</h3></li>
               <li><h3>Criação de marca</h3></li>
               <li><h3>Gestão de redes sociais</h3></li>
               <li><h3>Otimização de SEO</h3></li>
            </ul>
         </b-container>
      </section>

      <section class="section-parceiros">
         <b-container class="h-100">
            <h2 class="title-section">ESSES SÃO ALGUNS DOS <span>NOSSOS PARCEIROS</span></h2>
         </b-container>
         <div class="box-parceiros">
            <b-container class="h-100">
               <VueSlickCarousel v-bind="carrossel_parceiros">
                  <div class="logo-parceiro"><img src="@/assets/images/logo-boxys.png" alt=""></div>
                  <div class="logo-parceiro"><img src="@/assets/images/logo-mavel.png" alt=""></div>
                  <div class="logo-parceiro"><img src="@/assets/images/logo-moove.png" alt=""></div>
                  <div class="logo-parceiro"><img src="@/assets/images/logo-pay.png" alt=""></div>
                  <div class="logo-parceiro"><img src="@/assets/images/logo-stelas.png" alt=""></div>
                  <div class="logo-parceiro"><img src="@/assets/images/logo-oficina2.png" alt=""></div>
                  <div class="logo-parceiro"><img src="@/assets/images/logo-odyn.png" alt=""></div>
               </VueSlickCarousel>
            </b-container>
         </div>
      </section>

      <div class="borda-blockchain"></div>

      <section class="section-servicos">
         <b-container class="h-100">  
            <h2 class="title-section">FICOU INTERESSADO NOS <span>NOSSOS SERVIÇOS?</span></h2>
            <div class="box-texto">
               <p>Acesse aqui nosso formulário de orçamento, logo retornaremos com a resposta para te ajudar no seu produto.</p>
               <div class="bt-mail">
                  <a href="mailto:daniel@insightsolution.com.br"><i><img src="@/assets/images/email-edit-outline.svg" alt=""></i>Envie um e-mail agora</a>
               </div>
            </div>
         </b-container>
      </section>
      
      <section class="section-clientes">
         <b-container class="h-100">  
            <div class="box-clientes">
               <h2 class="title-section">OPINIÃO DOS <span>NOSSOS CLIENTES</span></h2>

               <VueSlickCarousel v-bind="carrossel_clientes">
                  <div class="msg-cliente">
                     <div class="nome">Marcela</div>
                     <div class="cargo">Marka Fidelização</div>
                     <p>O nível de inteligência, comprometimento e parceria que o Daniel agregou ao projeto fez total diferença para os resultados que alcançamos!</p>
                  </div>

                  <div class="msg-cliente">
                     <div class="nome">Maicon de Siqueira Bontorin</div>
                     <div class="cargo">Academia Moove</div>
                     <p>Empresa sería, competente, que está a muito tempo no mercado, que faz com que seus clientes saiam feliz e com os resultados esperados. Está eu indico, pois eles são mais que uma empresa, são uma familia. Obrigado pelo apoio, dedicação e por estar junto conosco enfrentando todas as adversidades que passamos neste último ano.</p>
                  </div>
               </VueSlickCarousel>
            </div>
         </b-container>
      </section>
   </div>
</template>

<script>
   import Blockchain from '@/components/Blockchain/Blockchain';
   import { UAnimateContainer, UAnimate } from "vue-wow";

   import VueSlickCarousel from 'vue-slick-carousel'
   import 'vue-slick-carousel/dist/vue-slick-carousel.css'
   import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

   
   export default {
      name: 'Home',
      components: {
         Blockchain,
         UAnimateContainer,
         UAnimate,
         VueSlickCarousel
      },
      data() {
         return {
            settings: {
               "dots": true,
               "arrows": true,
               "focusOnSelect": true,
               "infinite": true,
               "speed": 2500,
               "autoplay": true,
               "autoplaySpeed": 6000,
               "slidesToShow": 4,
               "slidesToScroll": 1,
               "responsive": [
                  {
                     "breakpoint": 1950,
                     "settings": {
                        "slidesToShow": 3,
                        "slidesToScroll": 1,
                     }
                  },
                  {
                     "breakpoint": 1200,
                     "settings": {
                        "slidesToShow": 2,
                        "slidesToScroll": 1,
                     }
                  },
                  {
                     "breakpoint": 575,
                     "settings": {
                        "slidesToShow": 1,
                        "slidesToScroll": 1
                     }
                  }
               ]
            },
            carrossel_parceiros: {
               "dots": true,
               "arrows": false,
               "focusOnSelect": true,
               "infinite": true,
               "speed": 2500,
               "autoplay": true,
               "autoplaySpeed": 2000,
               "slidesToShow": 7,
               "slidesToScroll": 7,
               "responsive": [
                  {
                     "breakpoint": 767,
                     "settings": {
                        "slidesToShow": 4,
                        "slidesToScroll": 4,
                     }
                  },
                  {
                     "breakpoint": 575,
                     "settings": {
                        "slidesToShow": 2,
                        "slidesToScroll": 2
                     }
                  }
               ]
            },
            carrossel_clientes: {
               "dots": true,
               "arrows": false,
               "focusOnSelect": true,
               "infinite": true,
               "adaptiveHeight": true,
               "speed": 2500,
               "autoplay": true,
               "autoplaySpeed": 2000,
               "slidesToShow": 3,
               "slidesToScroll": 3,
               "responsive": [
                  {
                     "breakpoint": 992,
                     "settings": {
                        "slidesToShow": 2,
                        "slidesToScroll": 2,
                     }
                  },
                  {
                     "breakpoint": 767,
                     "settings": {
                        "slidesToShow": 1,
                        "slidesToScroll": 1
                     }
                  }
               ]
            },
         }
      },
   }
</script>


<style lang="scss">
  @import "Home.scss";
</style>
